import icon1 from '../images/icon1.png'
import icon2 from '../images/icon2.png'
import icon3 from '../images/icon3.png'
import icon4 from '../images/icon4.png'
import icon5 from '../images/icon5.png'
import icon6 from '../images/icon6.png'
import pic1 from '../images/Pic1.png'
import pic2 from '../images/Pic2.png'
import pic3 from '../images/Pic3.png'
import pic4 from '../images/Pic4.png'


export const heroContetnt ={
    title:'Join Us To Improve Your Business',
    contetnt: 'But i must explain to you all this mistaken idea of denouncing pleasure and praising pain was born and will give complete.'
}
export const aboutUs ={
    title:'We Are Landing International Consulting Agency',
    content:'We are Landing International Consulting Agency On The hand, we denounce with righteous indignation and dislike men who are so beguiled',
    list:[
        {
            key:1,
            listContent:'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure because those who do not know how to pursue pleasure',
        },
        {
            key:2,
            listContent:'But I must explain to you how all this mistaken idea of denouncing ple when iseu asure and praising pain was born',
        },
        {
            key:3,
            listContent:'At vero eos et accusamus et iusto odio dignissimus ducimus prasentum voluptatum deleniti atque.',
        }
    ]
}
export const survices ={
    title:'We Provide Service',
    content:[
        {
            key:1,
            icon: icon1,
            head:'Business Consulting',
            paragraph:'But I must explain to you how all this mistaken idea of denouncing.'   
        },
        {
            key:2,
            icon: icon2,
            head:'Investment Planning',
            paragraph:'But I must explain to you how all this mistaken idea of denouncing.' 
        },
        {
            key:3,
            icon: icon3,
            head:'Travel & Tour Consulting',
            paragraph:'But I must explain to you how all this mistaken idea of denouncing.' 
        },
        {
            key:4,
            icon: icon4,
            head:'SEO And Digital Marketing',
            paragraph:'But I must explain to you how all this mistaken idea of denouncing.' 
        },
        {
            key:5,
            icon: icon5,
            head:'Insurance And Banking',
            paragraph:'But I must explain to you how all this mistaken idea of denouncing.' 
        },
        {
            key:6,
            icon: icon6,
            head:'Environment Consulting',
            paragraph:'But I must explain to you how all this mistaken idea of denouncing.' 
        },
        
    ]
}
export const portflio ={
    title:'Meet Our Team',
    content:[
        {
            key:1,
            image: pic1,
            name:'Heather. J Tice',
            job:'Interior Designer'
        },
        {
            key:2,
            image: pic2,
            name:'John David',
            job:'Developer'
        },
        {
            key:3,
            image: pic3,
            name:'Glen M. Mendez',
            job:'Founder'
        },
        {
            key:4,
            image: pic4,
            name:'John David',
            job:'Web Designer'
        },
    ]
}
export const footer ={
    paragraph:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
    title2:[
        {
            title:'Use Full Links',
            list1:'About Us',
            list2:'Latest Services',
            list3:'News & Blog',
            list4:'Our Features',
        }
    ],
    title3:[
        {
            title:'Contact Us',
            icon1:'',
            list1:'7124 Brickyard St. Lutherville Timonium, MD 21093',
            icon2:'',
            list2:'sales@information.com',
            icon3:'',
            list3:'+ 1-234-222-0166',
        }
    ],
    title4:[
        {
            title:'Get In Touch',
        }
    ]
}
